import React from "react";
import LanguageSelect from "../components/shared-components/LanguageSelect";

export default function ContentWrapper({ children }) {
  return (
    <div className="layout-container">
      <div className="language-select">
        <LanguageSelect />
      </div>
      <div>{children}</div>
      {/* <div>
        <Footer />
      </div> */}
    </div>
  );
}
