export const state = {
  language: "en",

  activeStep: 1,
  numberOfSteps: 5,

  program: null,
  applicationType: null,

  options: [],

  checklist: null,
};
