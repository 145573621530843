import React, { lazy } from "react";

const WelcomePage = lazy(() => import("../pages/WelcomePage"));
const ProgramsPage = lazy(() => import("../pages/ProgramsPage"));
const ApplicationTypesPage = lazy(() =>
  import("../pages/ApplicationTypesPage")
);
const QuestionnairePage = lazy(() => import("../pages/QuestionnairePage"));
const ResultPage = lazy(() => import("../pages/ResultPage"));

export const routes = [
  {
    path: "get-started",
    element: <WelcomePage />,
  },
  {
    path: "programs",
    element: <ProgramsPage />,
  },
  { path: "application-types", element: <ApplicationTypesPage /> },
  { path: "questionnaire", element: <QuestionnairePage /> },
  { path: "result", element: <ResultPage /> },
  { path: "*", element: <WelcomePage /> },
];
