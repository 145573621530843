import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const lan = JSON.parse(sessionStorage.getItem("appState"))?.language;

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: lan,
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    am: {
      translations: require("./locales/am/translations.json"),
    },
    zh: {
      translations: require("./locales/zh/translations.json"),
    },
    fr: {
      translations: require("./locales/fr/translations.json"),
    },
    ko: {
      translations: require("./locales/ko/translations.json"),
    },
    es: {
      translations: require("./locales/es/translations.json"),
    },
    vi: {
      translations: require("./locales/vi/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "am", "zh", "fr", "ko", "es", "vi"];

export default i18n;
