import React, { createContext, useState, useEffect } from "react";
import { state } from "./appState";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState(() => {
    const storedState = sessionStorage.getItem("appState");
    return storedState ? JSON.parse(storedState) : state;
  });

  useEffect(() => {
    sessionStorage.setItem("appState", JSON.stringify(appState));
  }, [appState]);

  const updateState = (newValues) => {
    setAppState((prevState) => ({ ...prevState, ...newValues }));
  };

  const handleOptionSelect = (option, question, isMultiSelect = false) => {
    if (isMultiSelect) {
      const optionAvailable = appState[question]?.find(
        (sel) => sel.id === option.id
      );
      if (optionAvailable) {
        let temSelected = [...appState[question]];
        temSelected = temSelected.filter((sel) => sel.id !== option.id);
        updateState({ [question]: temSelected });
      } else {
        if (appState[question]?.length > 0) {
          updateState({ [question]: [...appState[question], option] });
        } else {
          updateState({ [question]: [option] });
        }
      }
    } else {
      updateState({ [question]: [option] });
    }
  };

  const handleAnswerSelect = (answer, question, isMultiSelect = false) => {
    const temporaryOptions = [...appState.options];

    const optionIndex = temporaryOptions.findIndex(
      (op) => op.question === question
    );
    let answersArray = [];

    if (optionIndex !== -1) {
      if (isMultiSelect) {
        let temporaryAnswers = temporaryOptions[optionIndex].answers;
        const answerAvailable = temporaryAnswers.find(
          (ans) => ans.value === answer.value
        );

        if (answerAvailable) {
          let temSelected = [...temporaryAnswers];
          temSelected = temSelected.filter((an) => an.value !== answer.value);
          temporaryAnswers = temSelected;
        } else {
          if (temporaryAnswers?.length > 0) {
            temporaryAnswers = [...temporaryAnswers, answer];
          } else {
            temporaryAnswers = [answer];
          }
        }
        answersArray = temporaryAnswers;
      } else {
        answersArray = [answer];
      }
      temporaryOptions[optionIndex] = {
        question,
        answers: answersArray,
      };

      updateState({ options: [...temporaryOptions] });
    } else {
      if (temporaryOptions.length === 0) {
        updateState({
          options: [
            {
              question,
              answers: [answer],
            },
          ],
        });
      } else {
        updateState({
          options: [
            ...temporaryOptions,
            {
              question,
              answers: [answer],
            },
          ],
        });
      }
    }
  };

  return (
    <AppContext.Provider
      value={{
        state: appState,
        updateState,
        handleOptionSelect,
        handleAnswerSelect,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
