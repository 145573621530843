import React, { useContext } from "react";
import { AppContext } from "../../state/AppContext";
import StepperFooter from "./StepperFooter";

export default function MainStepper() {
  const { state } = useContext(AppContext);
  const { activeStep, numberOfSteps } = state;

  return (
    <div className="steps-area steps-area-fixed">
      <div className="image-holder" />

      <div className="steps">
        <ul className="tablist multisteps-form__progress">
          {Array.from(Array(numberOfSteps).keys()).map((value) => (
            <li
              key={value}
              className={`multisteps-form__progress-btn ${
                value === numberOfSteps - 1 && "last "
              }
                ${value + 1 <= activeStep && "js-active current"}
                `}
            >
              <span>{value + 1}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="desktop-logo">
        <img
          src="/assets/v1/img/dhs-logo.webp"
          alt="logo"
          width="161px"
          height="77px"
          loading="lazy"
        />
      </div>
      <div className="steps-footer">
        <StepperFooter />
      </div>
    </div>
  );
}
