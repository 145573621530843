import React from "react";
import { getFooterData } from "../services/appData.service";
import { useTranslation } from "react-i18next";

export default function StepperFooter() {
  const data = getFooterData();
  const { t } = useTranslation();
  return (
    <footer className="stepper-footer">
      <div className="container">
        <div className="stepper-footer-content">
          <h6>{t("CONNECT_WITH_DHS")}</h6>
          <div className="stepper-footer-contact mt-2">
            <p>
              {t("BENEFITS")}:{" "}
              <span className="stepper-contact">{data.benefits}</span>
            </p>
            <p>
              {t("ESA_CALL_CENTER")}:{" "}
              <span className="stepper-contact">{data.esaCallCenter}</span>
            </p>
            <p>
              {t("HOMELESS_SERVICES_HOTLINE")}:{" "}
              <span className="stepper-contact">
                {data.homelessServicesHotline}
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
